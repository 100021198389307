import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ReactDOMServer from 'react-dom/server';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import request from '~utils/request';
import Link from '~components/Link';
import PopupCloseButton from '~modules/account/components/forms/shared/PopupCloseButton';
import openHtmlAsPdf from '~src/utils/open-html-as-pdf';
import { USER_PAYMENT_HISTORY } from '~hooks/useApi';
import PaymentHistoryPage from './PaymentHistoryPage';
import LoadingSpinner from '../LoadingSpinner';

const useStyles = makeStyles(() => ({
  popupFooter: {
    padding: '0.5em'
  },
  col_first_item: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#4B5161',
    marginBottom: '16px'
  },
  box_title: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#4B5161'
  },

  col_item: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#4B5161',
    marginBottom: '22px'
  }
}));

const ClassesContext = createContext();
const ClickHandlerContext = createContext();

const YearSelectorLine = ({ year }) => {
  const { box_title: boxTitleClass } = useContext(ClassesContext);
  const handlePaymentHistory = useContext(ClickHandlerContext);
  return (
    <Box bgcolor="#fff" py={3} px={4} mb={2} border="2px solid #EFEFEF" width="100%">
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={6}>
          <Typography className={boxTitleClass}>{year} - Tax Receipt</Typography>
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'right' }}>
          <Button
            style={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
              marginRight: '-8px',
              marginTop: '-6px'
            }}
            color="primary"
            onClick={() => handlePaymentHistory(year)}
          >
            VIEW
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
YearSelectorLine.propTypes = {
  year: PropTypes.number.isRequired
};

const YearSelectorLinesSequence = ({ fromYearInclusive, toYearInclusive }) => {
  const result = [];
  for (let i = toYearInclusive; i >= fromYearInclusive; --i) {
    result.push(<YearSelectorLine key={`year-selector-${i}`} year={i} />);
  }
  return result;
};

const YearSelectorPopup = ({ handlePaymentHistory, onClose }) => {
  const classes = useStyles();

  const now = new Date();
  const currentYear = now.getUTCFullYear();
  const lastYearToShow = currentYear - 1; // payment history for the current year, which didn't end yet, is useless.
  const firstYearToShow = currentYear - 5; // arbitrarily made decision to support the latest 5 completed years.

  return (
    <>
      <div>
        <Typography align="left" className={classes.col_first_item}>
          Year-End Tax
        </Typography>
        <Typography align="left" className={classes.col_item}>
          Don’t see all your donations here?{' '}
          <Link to="mailto:support@alephbeta.org">Contact support</Link> and we’ll help.
        </Typography>
      </div>
      <div>
        <ClassesContext.Provider value={classes}>
          <ClickHandlerContext.Provider value={handlePaymentHistory}>
            <YearSelectorLinesSequence
              fromYearInclusive={firstYearToShow}
              toYearInclusive={lastYearToShow}
            />
          </ClickHandlerContext.Provider>
        </ClassesContext.Provider>
        <Button variant="outlined" color="primary" fullWidth onClick={onClose}>
          Cancel
        </Button>
      </div>
      <PopupCloseButton handleClose={onClose} style={{ right: '5px', top: '5px' }} />
    </>
  );
};
YearSelectorPopup.propTypes = {
  handlePaymentHistory: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

const consolidateDonationRows = history =>
  history
    ? [
        ...(history.donations || []),
        ...(history.paypal.subscriptions || []),
        ...(history.paypal.recurring_donations || []),
        ...(history.stripe.recurring || []),
        ...(history.appstore.recurring || []),
        ...(history.gplay.recurring || [])
      ].sort((left, right) => left.timestamp_ms - right.timestamp_ms)
    : [];

const createPaymentHistoryPdf = async (user, history) => {
  const payments = consolidateDonationRows(history);
  const html = ReactDOMServer.renderToStaticMarkup(
    <PaymentHistoryPage user={user} payments={payments} year={history.year} />
  );
  return openHtmlAsPdf('receipt', { html });
};

const AccountPaymentHistory = ({ user, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);

  const renderPaymentHistory = year => {
    request(USER_PAYMENT_HISTORY({ year }))
      .then(({ data }) =>
        createPaymentHistoryPdf(user, data?.history).then(() => setIsLoading(false))
      )
      .catch(error => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handlePaymentHistory = year => {
    setIsLoading(true);
    renderPaymentHistory(year);
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}

      {!isLoading && (
        <YearSelectorPopup handlePaymentHistory={handlePaymentHistory} onClose={onClose} />
      )}
    </>
  );
};

AccountPaymentHistory.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string.isRequired,
    subscription_plan_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    subscription_plan_period: PropTypes.string.isRequired,
    subscription_expires_at: PropTypes.string.isRequired,
    subscription_gateway_stripe_id: PropTypes.string,
    subscription_gateway_paypal_id: PropTypes.string,
    subscription_gateway_appstore_id: PropTypes.string,
    subscription_gateway_gplay_id: PropTypes.string,
    access_level: PropTypes.number,
    is_admin: PropTypes.bool
  }).isRequired,
  onClose: PropTypes.func.isRequired
};

export default AccountPaymentHistory;
